import React, {Component} from "react"
import style from "./MascotteComponent.module.css"
import {api_url} from "../../../Services/ConnectionService";
import Lottie from "lottie-react";

class MascotteComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0
        }

        this.width = 1;
        this.height = 1;
        this._lottie = undefined;
    }

    getLottie() {
        if(this._lottie === undefined){
            let request = new Request(api_url + "/uploads/mascotte/" + this.props.file, {method:'POST'});

            fetch(request).then((response) =>response.json())
                .then(data => {
                    this._lottie = data;
                    this.width = data.w;
                    this.height = data.h;
                    this.forceUpdate();
                })
        }
    }


    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({width: width, height: height})
    }

    getMascotte() {
        if(this.props.type === "lottie"){
            if(this._lottie){
                return (
                    <Lottie
                        animationData={this._lottie}
                        loop={true}
                        style={{
                            height: "100%",
                            aspectRatio: this.width/this.height,
                            display: "flex",
                            alignItems: "center",
                            left: this.props.orientation === "droite" ? 0 : undefined,
                            right: this.props.orientation === "gauche" ? 0 : undefined,
                    }}/>
                )
            } else {
                this.getLottie()
            }
        } else {
            return (
                <img
                    src={api_url + '/uploads/mascotte/' + this.props.file}
                    alt={"Mascotte"}
                    style={{
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: this.props.orientation === "droite" ? "left top" : this.props.orientation === "gauche" ? "right top" : "center top",
                        left: this.props.orientation === "droite" ? 0 : undefined,
                        right: this.props.orientation === "gauche" ? 0 : undefined,
                    }}/>
            )
        }
    }

    transformDialogue() {
        let text = this.props.dialogue.replace(" ?", "\u00a0?")
        text = text.replace(" !", "\u00a0!")
        text = text.replace(" :", "\u00a0:")
        return text
    }

    getMaxWidth() {
        if(window.innerWidth > 1400) {
            if (this.props.materials) return "600px"
            else return "510px"
        }
        if(window.innerWidth > 1200){
            if (this.props.materials) return "60%"
            else return "50%"
        }
        if(window.innerWidth > 1000){
            if (this.props.materials) return "70%"
            else return "60%"
        }

        if (this.props.materials) return "80%"
        else return "70%"

    }

    render() {
        return (
            <div
                style={{
                    flex: this.props.size,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: this.props.height ?  this.props.height : "100%"
            }}>
                <div
                    className={style.dialogueContainer}
                    style={{
                        color: this.props.color ? this.props.color : "black",
                        fontWeight: 500,
                        justifyContent: this.props.orientation === "milieu" || this.props.orientation === null || this.props.orientation === "null" || this.props.center ? 'center' : (this.props.orientation === "droite" ? 'flex-start' : 'flex-end'),
                        alignItems: "flex-end",
                        marginLeft: this.props.margin ? this.props.margin : "5%",
                        maxWidth: this.getMaxWidth(),
                        alignSelf: this.props.orientation === "milieu" || this.props.orientation === null || this.props.orientation === "null" || this.props.center ? 'center' : (this.props.orientation === "droite" ? 'flex-start' : 'flex-end'),
                    }}
                    dangerouslySetInnerHTML={{ __html: this.transformDialogue()}}
                >
                </div>
                <div
                    ref={ (divElement) => { this.divElement = divElement } }
                    className={style.mascotteContainer}
                    style={{
                        display: 'flex',
                        justifyContent: this.props.orientation === "milieu" || this.props.orientation === null || this.props.orientation === "null" || this.props.center ? 'center' : (this.props.orientation === "droite" ? 'flex-start' : 'flex-end'),
                        marginLeft: this.props.orientation === "droite" && this.props.margin ? this.props.margin : undefined,
                        marginRight: this.props.orientation === "gauche"  && this.props.margin ? this.props.margin : undefined,
                        alignItems: "flex-start"
                    }}
                >
                    {this.getMascotte()}
                </div>
            </div>
        )
    }
}

export default MascotteComponent;
