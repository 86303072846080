import React, {Component} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import style from "./Picture.module.css"
import logo from "../../assets/Logo_bocal.svg"
import appstore from "../../assets/Store/appstore.png"
import playstore from "../../assets/Store/playstore.png"
import {QRCodeCanvas} from "qrcode.react";
import {faHome} from "@fortawesome/free-solid-svg-icons";

class Picture extends Component {

    constructor(props) {
        super(props);
        this.divHeight = 0;
    }

    getSize = (element) => {
        if(element && !this.divHeight) {
            this.divHeight = element.clientHeight
            this.forceUpdate()
        }
    }

    render() {
        return (
            <div
                className={style.container}
                ref={this.getSize}
            >
                <div className={style.title}>
                    Prends ta réalisation en photo !
                </div>

                <div className={style.text}>
                    <span>
                        Pour prendre une photo
                        { this.props.xp > 0 &&
                            <span> et obtenir {this.props.xp}
                                <img
                                    src={require("../../assets/Etoile.svg").default}
                                    style={{
                                        height: window.innerWidth > 1700 ? "40px" : window.innerWidth > 1400  ? "30px" : "20px",
                                        aspectRatio: 1,
                                        position: "relative",
                                        top: "7px", marginLeft: "4px"
                                }}
                                    ref={(node) => {this.starRef = node}}
                                />
                            ,</span>}
                        <span> </span>ouvre l’application mobile et scanne ce QR code !
                    </span>
                </div>

                <div className={style.qrcodeContainer}>
                    <QRCodeCanvas
                        value={this.props.qrCode}
                        size={this.divHeight/3}
                        level={"H"}
                        imageSettings={Object({
                            src: logo,
                            excavate: true,
                            height: this.divHeight/10,
                            width: this.divHeight/10,
                        })}
                    />
                </div>

                <div className={style.storeContainer}>
                    <img src={appstore} style={{height: "80%", maxHeight: "80px"}}/>
                    <img src={playstore} style={{height: "80%", maxHeight: "80px"}}/>
                </div>

                <div style={{flex: 2, display: "flex", alignItems: "center", justifyContent: "center"}}/>
            </div>
        )
    }
}

export default Picture;