import React, {Component} from "react"

class EmptyTurn extends Component {

    render() {
        return (
            <div style={{height: "90px", width: "30px", margin: "5px 0 0"}}/>
        )
    }
}

export default EmptyTurn;