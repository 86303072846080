import React, {Component} from "react"
import style from "./ActivityInfos.module.css"
import ThemeBadge from "./ThemeBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChildReaching, faClock, faUserGraduate} from "@fortawesome/free-solid-svg-icons";

class ActivityInfos extends Component {

    render() {
        return (
            <div className={style.activityInfos}>

                <div style={{display: "flex", gap: "20px", fontSize: "1.3vmax"}}>
                    <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faUserGraduate}/>
                        {this.props.ageMin} +
                    </div>

                    <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faClock}/>
                        {this.props.duration} min
                    </div>
                </div>

                <h1 style={{margin: "10px 0", fontSize: "1.7vmax"}}>{this.props.name}</h1>

                <div>
                    <ThemeBadge
                        baseColor={this.props.themeBaseColor}
                        bgColor={this.props.themeColor}
                        theme={this.props.theme}
                        style={{marginRight: "10px", padding: "5px 30px 5px 15px"}}
                        right={'-35px'}
                    />
                    {this.props.secondTheme &&
                        <ThemeBadge
                            baseColor={this.props.secondThemeBaseColor}
                            bgColor={this.props.secondThemeColor}
                            theme={this.props.secondTheme}
                            style={{padding: "5px 30px 5px 15px"}}
                            right={'-35px'}
                        />
                    }

                </div>


            </div>
        )
    }
}

export default ActivityInfos;