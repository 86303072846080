import CryptoJS from 'crypto-js'

const password = "RANDOM_ATORIKA_PASSWD_0147896325"

export function encode(id) {
    return window.btoa(CryptoJS.DES.encrypt(id.toString(), password).toString())
}

export function decode(enc) {
    let bytes = CryptoJS.DES.decrypt(window.atob(enc), password);
    return parseInt(bytes.toString(CryptoJS.enc.Utf8))
}
