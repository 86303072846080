import React, {Component} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import style from "./Circle.module.css"
import etoile from "../../../../assets/Etoile.svg"
import etoileGrise from "../../../../assets/EtoileGrise.svg"
import etoileCurrent from "../../../../assets/EtoileCurrent.svg"

class Circle extends Component {

    constructor(props) {
        super(props);
        this.fullWidth = false;
        this.circleRef = 0;
    }

    animate() {
        setTimeout(() => {this.fullWidth = true; this.forceUpdate()}, 400)
    }

    render() {

        if(this.props.current && !this.fullWidth && this.circleRef !== 0){
            this.animate()
        }

        return (
            <div
                className={style.circle}
                ref={(node) => {this.circleRef = node}}
                style={{
                    backgroundColor: this.props.isStar ? "transparent" : (this.props.current && this.fullWidth ? "#333" : this.props.done  ? "#6fa939" : "#EEEEEE"),
                    color: this.props.done || this.props.current ? "white" : "#6A6A6B",
                    WebkitTransition: "all .5s ease",
                    MozTransition: "all .5s ease",
                    zIndex: 15
            }}>
                <button onClick={() => {if((this.props.done || this.props.current) && this.props.navigation) this.props.changeStepNumber(this.props.number)}} className={((this.props.done || this.props.current) && this.props.navigation) ? style.btnClic : style.btn}>
                    {this.props.isStar ?
                        <div className={style.starContainer}>
                            <img src={this.props.current && this.fullWidth ? etoileCurrent : this.props.done ? etoile : etoileGrise} alt={this.props.done ? "Etoile gagnée" : "Etoile"} className={style.star}/>
                            <span className={style.starNumber}>{this.props.number}</span>
                        </div>

                        :
                        this.props.done && !this.props.current ?
                            <FontAwesomeIcon icon={faCheck} style={{fontSize: "1.8rem", color: "white"}}/>
                            :
                            <span style={{color: this.props.current && this.fullWidth ? "white" : "#6A6A6B"}}>{this.props.number}</span>
                    }
                </button>
            </div>
        )
    }
}

export default Circle;