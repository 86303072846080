import ConnectComponent, {api_url, client_url} from "../../../Services/ConnectionService";
import styles from "./BoxActivity.module.css"
import Loading from "../../Loading";
import BoxCard from "./BoxCard";


export default class BoxActivity extends ConnectComponent {

    constructor(props) {
        super(props);
        this.loaded = false;
        this.isFirst = true;
    }

    doesMustLog() {
        this.mustLog = 1
    }

    getBoxes() {
        let data = new FormData();
        data.append('json', JSON.stringify({qrcode: this.props.uuid}));
        let request = new Request(api_url + '/activity/boxes', {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (json) => {
            switch (json.type) {
                case "FOUND":
                    this.loaded = true
                    this.setState({boxes: json.boxes})
                    break;
                case "NOTFOUND":
                    this.loaded = true
                    this.setState({boxes: []})
                    break;
                case "ERROR":
                    this.goError()
                    break;
            }
        })
    }

    displayBoxes() {
        let boxes = [];
        if(this.state.boxes.length % 2 === 0) boxes.push(<div className={styles.break}/>)
        for (let boxesKey in this.state.boxes) {
            if((parseInt(boxesKey) + this.state.boxes.length) % 2 === 0 && boxesKey !== 0){
                boxes.push(<div className={styles.break}/>)
            }
            boxes.push(
                <BoxCard
                    box={this.state.boxes[boxesKey]}
                    link={client_url + '/additem/' + this.state.boxes[boxesKey].reference}
                />
            )
        }

        return boxes
    }

    renderChild() {
        if(this.isFirst) {
            this.isFirst = false
            this.getBoxes()
        }
        if(this.loaded) {
            return (
                <div style={{width: '100%', height: '100%', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                    <div
                        className={styles.mainContainer}
                        style={{
                            width: window.innerWidth > 1020 ? "1020px" : "100%",
                    }}>

                        <div className={styles.cardContainer} style={{flexWrap: "wrap"}}>

                            <div className={styles.childCard}>
                                <div
                                    className={styles.card}
                                    style={{
                                        padding: "30px",
                                        backgroundColor: "white",
                                        boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                        borderRadius: "5px",
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 0
                                    }}
                                >
                                    <h1 style={{textAlign: "center"}}>
                                        Oups !
                                    </h1>

                                    <p style={{textAlign: "center", margin: "0 15% 20px"}}>
                                        Il vous manque une autorisation pour accéder à ce tutoriel.
                                    </p>


                                    <p style={{textAlign: "center", margin: "0 15%"}}>
                                        Vous pouvez retrouver cette activité dans une de ces box.
                                    </p>
                                </div>
                            </div>

                            {this.displayBoxes()}
                        </div>


                        <div
                            style={{
                                flex: 1,
                                width: window.innerWidth > 1020 ? "1020px" : "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}>

                            <div className={styles.cardContainer} style={{flexWrap: "wrap"}}>

                                <div className={styles.childCard}>
                                    <div
                                        className={styles.card}
                                        style={{
                                            padding: "30px",
                                            backgroundColor: "white",
                                            boxShadow: "1px 1px 4px 0 rgb(0 0 0 / 20%)",
                                            borderRadius: "5px",
                                            flex: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 0
                                        }}
                                    >
                                        <h2 style={{textAlign: "center"}}>
                                            Vous avez un code d'activation ?
                                        </h2>

                                        <p style={{textAlign: "center", margin: "0 15% 20px"}}>
                                            Ce code est nécessaire pour pouvoir accéder aux tutoriels liés à votre box ou votre abonnement.
                                        </p>

                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}}>
                                            <a className={styles.defaultBtn}  href={client_url + "/activation"} style={{fontSize: "1.2rem"}}>
                                                Utiliser
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            )
        }
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: "absolute",
                    left: 0, top: 0
            }}>
                <Loading/>
            </div>
        )
    }

}

