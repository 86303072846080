import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import Empty from "../components/Empty";
import Loading from "../components/Loading";

export const mapStateToProps = (state) => state

export const api_url = 'https://api.test.atorika.fr'
export const client_url = 'https://client.test.atorika.fr'
export const help_url = 'https://help.atorika.fr'

export async function checkIfConnected () {
    let req = new Request(api_url + '/client/user/me', {credentials:'include'})
    let resp = await fetch(req)
    if (resp.status === 200){
        let user_data = await resp.json()
        return {
            conn: true,
            user : user_data.user,
            children : user_data.childs,
            basket: user_data.basket
        }
    }
    return {conn: false}
}

export function addFlash(dispatch, type, content) {
    dispatch({
        type:"ADD_FLASH",
        flashType: type,
        flashContent: content
    })
}

export default class ConnectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user : {
                birthDate : {},
                addresses : []
            },
            children : [],
            basketNb: 0,
            shouldNavigate: false,
            navigateTo: '/',
            isOK: false,
        }
        this.mustSecure = true
        this.mustLog = 0
        this.isConnected = false
        this.doRender = false
    }

    security() {
        this.doesMustLog()
        checkIfConnected().then((result) => {
            this.isConnected = result.conn
            if(!result.conn && this.mustLog === 1) {
                this.setState({shouldNavigate: true, navigateTo: client_url + '/login?_redirect=' + window.location.href})
            }
            if (result.conn && this.mustLog === -1) {
                this.setState({shouldNavigate: true, navigateTo: '/'})
            }
            if (result.conn && this.mustLog === 1) {
                this.setState({user: result.user, children: result.children, basketNb: result.basket, isOK: true})
            }
            if (this.mustLog === 0 || (this.mustLog === -1 && !result.conn)) {
                this.setState({isOK: true})
            }
        })
    }

    fetchJsonOrError(request, callBack, customError = null, notJson = null)
    {
        fetch(request).then(notJson ? notJson : (response) => {
            if(response.status === 200){
                return response.json()
            } else if (customError) {
                customError()
            } else {
                this.goError()
            }
        }).then(callBack)
    }

    goError() {
        this.setState({shouldNavigate: true, navigateTo: '/error'})
    }

    navigate() {
        if(this.state.navigateTo.startsWith('https://')) {
            window.location.replace(this.state.navigateTo)
            return (<Empty/>)
        }
        return (
            <Navigate to={this.state.navigateTo}/>
        )
    }

    setDoRender = () => {
        this.doRender = true
        this.forceUpdate()
    }

    exitFullScreen() {
        document.exitFullscreen();
    }

    render () {
        if(this.mustSecure) {
            this.security()
            this.mustSecure = false
        }
        if(this.state.shouldNavigate){
            return this.navigate();
        }
        if(!this.state.isOK){
            return (
                <div
                    style={{
                        position: "absolute",
                        left: 0, top: 0,
                        opacity: '100%',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white"
                    }}
                >
                    <Loading/>
                </div>
            )
        }
        if(!this.doRender){
            this.renderChild()
            setTimeout(this.setDoRender.bind(this), 500)
        }
        return (
            <div>
                {this.renderChild()}
            </div>
        )
    }
}
