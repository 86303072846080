import React, {Component} from "react"
import reactImageSize from "react-image-size";
import {api_url} from "../../../Services/ConnectionService";

class MascotteQuizComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0,
            mascotteHeight: 0,
            mascotteRatio: 1,
            margin: this.props.margin ? this.props.margin : 90
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({ height: height, width: width, mascotteHeight: 0.8*height});
    }

    transformDialogue() {
        let text = this.props.dialogue.replace(" ?", "\u00a0?")
        text = text.replace(" !", "\u00a0!")
        text = text.replace(" :", "\u00a0:")
        return text
    }

    onImgLoad({target:img}) {
        this.setState({mascotteRatio: img.offsetWidth/img.offsetHeight});
    }



    render() {
        return (
            <div
                style={{
                    flex: 1,
                    alignItems: this.props.center || this.props.orientation === "milieu" || this.props.orientation === null
                                || this.props.orientation === "null" ? 'center' : (this.props.orientation === "droite" ?
                                'flex-start' : 'flex-end'),
                    justifyContent: 'flex-end',
                    display: "flex",
                    flexDirection: "column",
                    position: "relative"
                }}
                ref={ (divElement) => { this.divElement = divElement } }
            >
                <img
                    src={api_url + '/uploads/mascotte/' + this.props.file}
                    onLoad={this.onImgLoad.bind(this)}
                    style={{
                        height: this.state.mascotteHeight + "px",
                        position: "relative",
                        left: this.props.orientation === "droite"  ? this.state.margin + "px" : undefined,
                        right: this.props.orientation === "gauche"  ? this.state.margin + "px" : undefined,
                }}
                />

                <span
                    style={{
                        position: 'absolute',
                        bottom: this.props.orientation === "milieu" ? this.state.mascotteHeight : 0.70 * this.state.mascotteHeight + 15,
                        left: this.props.orientation === "droite" ? this.state.margin + 15 + 0.40 * this.state.mascotteRatio * this.state.mascotteHeight : (this.props.orientation === "gauche" ? undefined : 15),
                        right: this.props.orientation === "gauche" ? this.state.margin + 15 * this.state.mascotteRatio * this.state.mascotteHeight : (this.props.orientation === "gauche" ? undefined : 15),
                        textAlign: this.props.orientation === "droite" ? "left" : (this.props.orientation === "gauche" ? "right" : "center"),
                        width: this.props.orientation === "droite" || this.props.orientation === "gauche" ? this.state.width - 0.40 * this.state.mascotteRatio * this.state.mascotteHeight - this.state.margin - 35 : this.state.width - this.state.margin - 25,
                        fontSize: "1.5vmax",
                        color: "black",
                        fontWeight: 500,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.transformDialogue()}}
                >

                </span>
            </div>
        )
    }
}

export default MascotteQuizComponent;