import React, {Component} from "react"
import style from "./TitleComponent.module.css"

class TitleComponent extends Component {

    transformText() {
        let text = this.props.title.replace(" ?", "\u00a0?")
        text = text.replace(" !", "\u00a0!")
        text = text.replace(" :", "\u00a0:")
        return text
    }

    render() {
        return (
            <div style={{flex: this.props.size}} className={style.title}>
                {this.transformText()}
            </div>
        )
    }
}

export default TitleComponent;