import React, {Component} from "react"
import style from "./Circle.module.css"


class EmptyCircle extends Component {

    render() {
        return (
            <div className={style.circle}/>
        )
    }
}

export default EmptyCircle;