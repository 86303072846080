import {useParams} from "react-router-dom";
import React from "react";
import BoxActivity from "./BoxActivity/BoxActivity";

function GetUuidActivity () {
    let {uuid} = useParams()

    return (
        <BoxActivity uuid={uuid}/>
    )
}

export default GetUuidActivity;
