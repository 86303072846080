import React, {Component} from "react"

class TextComponent extends Component {


    transformText() {
        let newText = this.props.text;

        newText = newText.replace(" ?", "\u00a0?")
        newText = newText.replace(" !", "\u00a0!")
        newText = newText.replace(" :", "\u00a0:")

        let started = false;
        let words = "";
        let formatedText = "";
        for (let i = 0; i < newText.length; i++) {
            if(newText[i] === '*' && !started){
                started = true;
                formatedText += words;
                words = "";
            } else if (newText[i] === '*' && started) {
                formatedText += "<b>" + words + "</b>"
                words = "";
                started = false;
            } else {
                words = words + newText[i];
            }
        }

        formatedText += words;

        return formatedText
    }

    render() {
        return (
            <div
                style={{
                    flex: this.props.size,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    fontSize: "1.4vmax",
                    padding: "20px 40px",
                    maxHeight: this.props.size * 100 / 6 + "%",
                    marginTop: this.props.isFirst ? "15px" : 0
            }}>
                <span dangerouslySetInnerHTML={{__html: this.transformText()}} style={{flex: 1, textAlign: this.props.align ? this.props.align : "justify", overflowY: "auto", maxHeight: "100%", whiteSpace: "pre-wrap"}}>
                </span>
            </div>
        )
    }
}

export default TextComponent;
