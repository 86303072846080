import ConnectComponent, {api_url} from "../../Services/ConnectionService";
import {encode} from "../../Services/ObfuscatorService";
import Loading from "../Loading";
import Whois from "./Whois/Whois";


export default class ActivityHub extends ConnectComponent {

    constructor(props) {
        super(props);
        this.loaded = false;
        this.isFirst = true;
    }

    doesMustLog() {
        this.mustLog = 1
    }


    getHasRightActivity() {
        let data = new FormData();
        data.append('json', JSON.stringify({qrcode: this.props.uuid}));
        let request = new Request(api_url + '/activity/find', {method:'POST', body: data, credentials:'include'});
        this.fetchJsonOrError(request, (json) => {
            switch (json.type) {
                case "SINGLE":
                    this.setState({shouldNavigate: true, navigateTo: "/activity/start/" + encode(json.activity) + "/" + encode(json.user)})
                    break;
                case "MULTIPLE":
                    this.loaded = true
                    this.setState({jsonResponse: json})
                    document.title = json.activityData.name + " - App Web Atorika";
                    break;
                case "NONE":
                    this.setState({shouldNavigate: true, navigateTo: "/activity/box/" + this.props.uuid})
                    break;
                case "ERROR":
                    this.goError()
                    break;
            }
        })
    }

    chooseUser(user) {
        this.setState({shouldNavigate: true, navigateTo: "/activity/start/" + encode(this.state.jsonResponse.activity) + "/" + encode(this.state.jsonResponse.users[user].user.id)})
    }

    renderChild() {
        if(this.isFirst) {
            this.isFirst = false
            this.getHasRightActivity()
        }
        if(this.loaded) {
            return (
                <Whois
                    activity={this.state.jsonResponse.activityData}
                    mascotte={this.state.jsonResponse.mascotteWhoIs}
                    users={this.state.jsonResponse.users}
                    choose={this.chooseUser.bind(this)}
                />
            )
        }
        return (
            <div
                style={{
                    position: "absolute",
                    left: 0, top: 0,
                    opacity: '100%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white"
                }}
            >
                <Loading/>
            </div>
        )
    }

}

