import {useParams} from "react-router-dom";
import React from "react";
import Activity from "./Activity";
import {decode} from "../../Services/ObfuscatorService";

function GetIdsActivity () {
    let {activity, user} = useParams()

    return (
        <Activity act={decode(activity)} user={decode(user)}/>
    )
}

export default GetIdsActivity;
