import React, {Component} from "react"
import style from "./ProgressBar.module.css"
import Circle from "./ProgressBarElements/Circle";
import Line from "./ProgressBarElements/Line";
import TurnLeftBottom from "./ProgressBarElements/TurnLeftBottom";
import TurnRightBottom from "./ProgressBarElements/TurnRightBottom";
import TurnLeftTop from "./ProgressBarElements/TurnLeftTop";
import TurnRightTop from "./ProgressBarElements/TurnRightTop";
import EmptyTurn from "./ProgressBarElements/EmptyTurn";
import EmptyCircle from "./ProgressBarElements/EmptyCircle";
import EmptyLine from "./ProgressBarElements/EmptyLine";

class ProgressBar extends Component {

    constructor(props) {
        super(props);
        this.divRef = null;
        this.lineRefs = {};
        this.nbByLine = 0;
        this.nbLine = 0;
    }

    getNbByLine() {
        let w = window.innerWidth;
        let circle = 50;
        let line = 25;
        let turn = 30;
        let headerPrct = 0.4;
        let security = 30;


        let a = headerPrct * w - 2 * turn + line - security;
        let b = circle + line;

        return Math.floor(a/b);
    }

    scrollToCurrent = () => {
        if(this.divRef) {
            let lineNb = Math.floor((this.props.stepNumber)/this.nbByLine)
            let line = this.lineRefs[lineNb]
            let toTop = 400 - ((1 - lineNb)*line.offsetHeight)
            if(this.divRef.offsetHeight > this.nbLine*line.offsetHeight) {
                toTop = 400
            }
            this.divRef.scrollTo({
                top: toTop,
                behavior: 'smooth'
            })
        } else {
            setTimeout(() => {this.scrollToCurrent();}, 50)
        }
    }

    getProgressBar = (nbSteps, lastDone, nbByLine) => {
        let progressBar = [];
        let nbLine = (nbSteps % nbByLine === 0) ? (Math.floor(nbSteps/nbByLine)) : Math.floor(nbSteps/nbByLine) + 1;
        this.nbByLine = nbByLine
        this.nbLine = nbLine
        for(let i = 0; i < nbLine; i++){
            let line = [];
            if(i === 0){
                line.push(<EmptyTurn/>)
            } else if (i % 2 === 0) {
                line.push(<TurnRightTop done={i*nbByLine + 1 <= lastDone} current={i*nbByLine ===  this.props.stepNumber}/>)
            } else {
                line.push(<TurnLeftTop done={i*nbByLine + 1 <= lastDone} current={i*nbByLine ===  this.props.stepNumber}/>)
            }

            for(let j = 0; j < nbByLine; j++){
                let current = i*nbByLine + j + 1
                // let index = (Object.keys(this.props.starSteps).includes((i*nbByLine + j + 1).toString())) ? (i*nbByLine + j + 1) : 0;
                if(i*nbByLine + j + 1 > nbSteps){
                    line.push(<EmptyCircle/>)
                    if (j !== nbByLine - 1){
                        line.push(<EmptyLine/>)
                    }
                } else {
                    line.push(<Circle
                        number={current}
                        done={(current <= lastDone)} // || this.props.starSteps[index].done}
                        isStar={Object.keys(this.props.starSteps).includes(current.toString())}
                        changeStepNumber={this.props.changeStepNumber}
                        current={(current === this.props.stepNumber + 1)}
                        navigation={this.props.navigation}
                    />)

                    if(i*nbByLine + j + 1 === nbSteps && j !== nbByLine - 1){
                        line.push(<EmptyLine/>)
                    } else if (j !== nbByLine - 1){
                        line.push(<Line
                            done={i*nbByLine + j + 2 <= lastDone}
                            current={i*nbByLine + j + 1 === this.props.stepNumber}
                            orientation={i % 2 === 0 ? "left" : "right"}
                        />)
                    }

                }
            }

            if(i % 2 === 0 && i !== nbLine - 1){
                line.push(<TurnLeftBottom done={(i + 1)*nbByLine + 1 <= lastDone} current={(i + 1)*nbByLine ===  this.props.stepNumber}/>)
            } else if (i !== nbLine - 1) {
                line.push(<TurnRightBottom done={(i + 1)*nbByLine + 1 <= lastDone} current={(i + 1)*nbByLine ===  this.props.stepNumber}/>)
            } else {
                line.push(<EmptyTurn/>)
            }

            progressBar.push(
                <div style={{marginTop: i === 0 ? "400px": 0, marginBottom: 0, display: "flex", flexDirection: i % 2 === 0 ? "row" : "row-reverse"}} ref={(node) => {this.lineRefs[i] = node}}>
                    {line}
                </div>
            )
        }

        return progressBar;
    }

    render() {
        setTimeout(this.scrollToCurrent, 300)
        return (
            <div style={{flex: 1, display: "flex", flexDirection: "column", maxHeight: "100%"}}>

                <div className={style.triangle} style={{borderLeft: window.innerWidth*0.4 + "px solid transparent"}}/>
                <div className={style.progressBarContainer} ref={(node) => {this.divRef = node}}>

                    {this.props.progressBar && this.getProgressBar(this.props.nbSteps, this.props.lastDone, this.getNbByLine())}

                </div>

            </div>
        )
    }
}

export default ProgressBar;