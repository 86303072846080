import React, {Component} from "react"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause, faPlay, faUndo} from "@fortawesome/free-solid-svg-icons";
import style from "./TimerComponent.module.css"
import timer from "../../../assets/Sounds/Timer_end.mp3"

class TimerComponent extends Component {

    constructor(props) {
        super(props);
        this.totalDuration = this.props.duration;
        this.state = {
            play : false,
            finish : false,
            key: 0
        };
        this.audio = new Audio(timer)
        this.lastGiven = null;
        this.hasAdded = false;
    }

    render() {
        if( (this.props.nbStep !== this.props.stepFocus) && this.state.play && !this.state.finish && !this.hasAdded) {
            this.props.addTimer(true);
            this.hasAdded = true;
        }
        if((this.props.nbStep === this.props.stepFocus) && this.hasAdded) {
            this.hasAdded = false;
            this.props.addTimer(false);
        }

        const children = ({ remainingTime }) => {
            const minutes = Math.floor(remainingTime / 60)
            const seconds = remainingTime % 60
            if(minutes < 1){
                return `${seconds}`
            }
            if(seconds < 10){
                return `${minutes}:0${seconds}`
            }
            return `${minutes}:${seconds}`
        }

        const renderTime = ({ remainingTime }) => {
            if(this.hasAdded && remainingTime !== this.lastGiven) {
                this.lastGiven = remainingTime;
                this.props.setRemainingTime(remainingTime, this.totalDuration)
            }
            if (remainingTime === 0) {
                return (
                    <div style={{justifyContent: 'center', alignItems: 'center', display: "flex"}}>
                        <div style={{
                            color: '#aaa',
                            fontSize: "2vmax"
                        }}>C'est fini !</div>
                    </div>
                )
            }

            return (
                <div style={{justifyContent: 'center', alignItems: 'center', display: "flex", flexDirection: "column"}}>
                    <div style={{color: '#aaa', fontSize: "1.5vmax"}}>Il reste</div>
                    <div style={{fontSize: "2vmax", fontWeight: "600"}}>{children({remainingTime})}</div>
                    {remainingTime > 59 ?
                        <div style={{
                            color: '#aaa',
                            fontSize: "1.5vmax"
                        }}>
                            minutes
                        </div> :
                        <div style={{
                            color: '#aaa',
                            fontSize: "1.5vmax"
                        }}>secondes</div>
                    }
                </div>
            );
        };

        return (
            <div style={{flex: this.props.size, display: "flex", alignItems: "center", justifyContent: "center"}}>

                <button
                    onClick={() => {
                        this.setState({key: this.state.key + 1, play: this.state.play});
                    }}
                    className={style.btn}
                    style={{marginRight: "60px"}}
                >
                    <FontAwesomeIcon icon={faUndo} style={{fontSize: "2.5vmax", marginRight: "60px"}}/>
                </button>



                <CountdownCircleTimer
                    key={this.state.key}
                    isPlaying={this.state.play}
                    duration={parseInt(this.props.duration)}
                    colors={['#3EA79D', '#FFBA08', '#A30000']}
                    colorsTime={[this.props.duration, 1*this.props.duration/3, 0]}
                    size={300}
                    onComplete={() => {
                        this.audio.play();
                        this.setState({play: false, finish: true});
                        if(this.hasAdded) {
                            this.props.setRemainingTime(0, this.totalDuration)
                            setTimeout(() => {
                                this.props.addTimer(false)
                                this.hasAdded = false
                            }, 30000)
                        }
                    }}
                >
                    {renderTime}
                </CountdownCircleTimer>

                <button
                    onClick={() => {
                        if(this.state.finish){
                            this.setState({key : this.state.key + 1, finish: false});
                        }
                        this.setState({play: !this.state.play});
                    }}
                    className={style.btn}
                    style={{marginLeft: "60px"}}
                >
                    <FontAwesomeIcon icon={this.state.play ? faPause : faPlay}  style={{fontSize: "2.5vmax"}}/>
                </button>
            </div>
        )
    }
}

export default TimerComponent;