import React, {Component} from "react"
import star from "../../../assets/Etoile.svg"
import MascotteComponent from "./MascotteComponent";

class StarRainComponent extends Component {

    constructor(props) {
        super(props);
        this.starsRender = [];
        this.starsVar = [];
        this.isFirst = true;
        this.divWidth = 0;
        this.divHeight = 0;
        this.divTop = 0;
        this.divLeft = 0;
        this.container = null;
        this.allWent = false;
        this.areGoing = false;
    }

    calculateShowNext() {
        this.props.setShowNext(true)
    }

    calcAllWent() {
        let allWent = true
        for(let i in this.starsVar) {
            allWent = allWent && this.starsVar[i].went
        }
        this.allWent = allWent || this.props.hasAlready
    }

    async nextTrigger() {
        if(this.props.hasAlready) {
            return true
        }
        if(this.areGoing) {
            return false
        }
        if(!this.allWent) {
            this.areGoing = true;
            for(let i in this.starsVar) {
                setTimeout(() => {if(!this.starsVar[i].went) this.goToXpStar(i)}, i < this.starsVar.length/3 ? 10 : i > 2*this.starsVar.length/3 ? 3000 : 1500);
            }
            await new Promise(r => setTimeout(r, 4600));
            this.areGoing = false
        }
        return true
    }

    getSize = (element) => {
        if(element && !this.divWidth) {
            this.container = element
            this.isFirst = true
            this.divTop = element.getBoundingClientRect().top
            this.divLeft = element.getBoundingClientRect().left
            this.divWidth = element.clientWidth
            this.divHeight = element.clientHeight
            this.forceUpdate()
        }
    }

    getRandom(min, max) {
        return Math.floor(min + (max-min)*Math.random())
    }

    bound(min, max, value) {
        if(value < min) {
            return min
        }
        if(value > max) {
            return max
        }
        return value
    }

    getStarPos(star) {
        let transform = window.getComputedStyle(this.starsVar[star].ref).transform.toString()
        let currentX = parseInt(transform.split(',')[4])
        let currentY = parseInt(transform.split(',')[5])
        return {
            xMin : this.divLeft + this.divWidth/2 + currentX - 55,
            xMax : this.divLeft + this.divWidth/2 + currentX + 5,
            yMin : this.divTop - this.divHeight/9 + currentY - 5,
            yMax : this.divTop - this.divHeight/9 + currentY + 55,
        }
    }

    onMouseMove(event) {
        let {nativeEvent} = event
        for(let i in this.starsVar) {
            if(!this.starsVar[i].went) {
                let pos = this.getStarPos(i)
                if(nativeEvent.x > pos.xMin && nativeEvent.x < pos.xMax && nativeEvent.y > pos.yMin && nativeEvent.y < pos.yMax) {
                    this.starsVar[i].went = true
                    this.goToXpStar(i)
                }
            }
        }
    }

    onTouchMove(event) {
        let {nativeEvent} = event
        for(let i in this.starsVar) {
            if(!this.starsVar[i].went) {
                let pos = this.getStarPos(i)
                if(nativeEvent.touches[0].clientX > pos.xMin && nativeEvent.touches[0].clientX < pos.xMax && nativeEvent.touches[0].clientY > pos.yMin && nativeEvent.touches[0].clientY < pos.yMax) {
                    this.starsVar[i].went = true
                    this.goToXpStar(i)
                }
            }
        }
    }

    goToXpStar(star) {
        this.starsVar[star].went = true
        let newX = this.divWidth/2
        let newY = this.divHeight/9
        let transform = window.getComputedStyle(this.starsVar[star].ref).transform.toString()
        let currentX = parseInt(transform.split(',')[4])
        let currentY = parseInt(transform.split(',')[5])
        this.starsVar[star].ref.animate([
            {transform: "translate(" + currentX.toString() + "px, " + currentY.toString() + "px) rotate(" + this.starsVar[star].rotate.toString() + "deg)",},
            {transform: "translate(" + (currentX + (3*(newX - currentX))/4).toString() + "px, " + (currentY + (3*(newY - currentY))/4).toString() + "px) rotate(0deg)",},
        ], {
            duration: 805,
            easing: 'ease-in'
        })
        setTimeout( () => {this.starsVar[star].ref.animate([
            {
                transform: "translate(" + (currentX + (3*(newX - currentX))/4).toString() + "px, " + (currentY + (3*(newY - currentY))/4).toString() + "px) rotate(0deg)",
                width: this.starsVar[star].size.toString() + "px",
                height: this.starsVar[star].size.toString() + "px",
            },
            {
                transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(0deg)",
                width: 0,
                height: 0
            },
        ], {
            duration: 150,
            easing: 'ease-in'
        })}, 800)
        setTimeout(() => {
            this.starsVar[star].x = newX
            this.starsVar[star].y = newY
            this.starsVar[star].rotate = 0
            this.starsVar[star].size = 0
            this.calcAllWent()
            this.starsVar[star].ref.style = this.styleStringForStar(star)
            this.props.addXp(this.starsVar[star].xp)
            this.props.expandStar()
        }, 950)
    }

    styleStringForStar(star) {
        const base = "align-items: center; justify-content: center; position: absolute; z-index: 1000; "
        const pos = "top: " + (-this.divHeight/9).toString() + "px; right: " + (this.divWidth/2 - 25).toString() + "px; "
        const size = "width: " + this.starsVar[star].size.toString() + "px; height: " + this.starsVar[star].size.toString() + "px; "
        const transform = "transform: translate(" + this.starsVar[star].x.toString() + "px, " + this.starsVar[star].y.toString() + "px) rotate(" + this.starsVar[star].rotate.toString() + "deg);"
        return base + pos + size + transform
    }

    generateStarRain(nb) {
        if(this.props.focused && this.isFirst && this.divWidth) {
            this.isFirst = false
            let res = []
            const maxStars = 30;
            const each = Math.floor(nb/maxStars);
            const plus = nb % maxStars;
            const nbStars = Math.min(nb, maxStars)
            for(let i=0; i<nbStars; i++) {
                this.starsVar.push({
                    x: this.getRandom(-this.divWidth/2 + 50, this.divWidth/2 - 50),
                    y: 0,
                    rotate: 0,
                    size: this.getRandom(window.innerWidth/25 - 10, window.innerWidth/25 + 10),
                    went: false,
                    xp: each + (i < plus ? 1 : 0),
                    ref: React.createRef()
                })
                res.push(<div
                    key={i.toString()}
                    ref={(node) => {this.starsVar[i].ref = node}}
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: -this.divHeight/9,
                        right: this.divWidth/2 - 25,
                        width: this.starsVar[i].size,
                        height: this.starsVar[i].size,
                        zIndex: 1000,
                        transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + ")"
                }}>
                        <img src={star} style={{width: '100%', height: '100%'}} alt={'a star :)'}/>
                    </div>
                )
            }
            setTimeout(this.animateStarRain.bind(this), 700)
            this.starsRender = res;
            return res
        }
        else {
            return this.starsRender
        }
    }

    animateStarRain() {
        this.divTop = this.container.getBoundingClientRect().top
        this.divLeft = this.container.getBoundingClientRect().left
        for(let i in this.starsVar) {
            let newX = this.bound(-this.divWidth/2 + 50,this.divWidth/2 - 50,this.starsVar[i].x + this.getRandom(-50, 50))
            let changeX = newX - this.starsVar[i].x
            let newY = this.getRandom(15*this.divHeight/18, 8*this.divHeight/9)
            let newRotate = this.getRandom(50, 310)
            let duration = this.getRandom(2500, 3500)
            this.starsVar[i].ref.animate([
                {transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px) rotate(" + this.starsVar[i].rotate.toString() + "deg)"},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.12).toString() + "px, " + (newY*0.1089).toString() + "px) rotate(" + (newRotate*0.12).toString() + "deg)", offset: 0.12},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.24).toString() + "px, " + (newY*0.4356).toString() + "px) rotate(" + (newRotate*0.24).toString() + "deg)", offset: 0.24, easing: 'ease-in'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.36).toString() + "px, " + (newY*0.9801).toString() + "px) rotate(" + (newRotate*0.36).toString() + "deg)", offset: 0.36, easing: 'ease-out'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.54).toString() + "px, " + (newY*0.7502).toString() + "px) rotate(" + (newRotate*0.54).toString() + "deg)", offset: 0.54, easing: 'ease-in'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.74).toString() + "px, " + (newY*0.9837).toString() + "px) rotate(" + (newRotate*0.74).toString() + "deg)", offset: 0.74, easing: 'ease-out'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.82).toString() + "px, " + (newY*0.9375).toString() + "px) rotate(" + (newRotate*0.82).toString() + "deg)", offset: 0.82, easing: 'ease-in'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.92).toString() + "px, " + (newY*0.9934).toString() + "px) rotate(" + (newRotate*0.92).toString() + "deg)", offset: 0.92, easing: 'ease-out'},
                {transform: "translate(" + (this.starsVar[i].x + changeX*0.96).toString() + "px, " + (newY*0.9846).toString() + "px) rotate(" + (newRotate*0.96).toString() + "deg)", offset: 0.96, easing: 'ease-in'},
                {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
            ], {
                duration: duration,
            })
            /*this.starsVar[i].ref.animate([
                {transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px) rotate(" + this.starsVar[i].rotate.toString() + "deg)"},
                {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
            ], {
                duration: duration*0.8,
                easing: 'ease-in'
            })
            setTimeout(() => {
                if(!this.starsVar[i].went) {
                    this.starsVar[i].ref.animate([
                        {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                        {transform: "translate(" + newX.toString() + "px, " + (newY*0.9).toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                    ], {
                        duration: duration*0.1,
                        easing: 'ease-out'
                    })
                }
            }, duration*0.8)
            setTimeout(() => {
                if(!this.starsVar[i].went) {
                    this.starsVar[i].ref.animate([
                        {transform: "translate(" + newX.toString() + "px, " + (newY * 0.9).toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                        {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                    ], {
                        duration: duration*0.1,
                        easing: 'ease-in'
                    })
                }
            }, duration*0.9)
            setTimeout(() => {
                if(!this.starsVar[i].went) {
                    this.starsVar[i].ref.animate([
                        {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                        {transform: "translate(" + newX.toString() + "px, " + (newY*0.95).toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                    ], {
                        duration: duration*0.1,
                        easing: 'ease-out'
                    })
                }
            }, duration)
            setTimeout(() => {
                if(!this.starsVar[i].went) {
                    this.starsVar[i].ref.animate([
                        {transform: "translate(" + newX.toString() + "px, " + (newY * 0.95).toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                        {transform: "translate(" + newX.toString() + "px, " + newY.toString() + "px) rotate(" + newRotate.toString() + "deg)"},
                    ], {
                        duration: duration * 0.1,
                        easing: 'ease-in'
                    })
                }
            }, duration*1.1)*/
            this.starsVar[i].x = newX
            this.starsVar[i].y = newY
            this.starsVar[i].rotate = newRotate
            setTimeout(() => {if(!this.starsVar[i].went) this.starsVar[i].ref.style = this.styleStringForStar(i)}, duration)
        }
    }

    render() {
        return (
            <div
                style={{flex: 1, display: 'flex', flexDirection: 'column', height: "100%"}}
                ref={this.getSize}
                onMouseMove={this.onMouseMove.bind(this)}
                onTouchMove={this.onTouchMove.bind(this)}
            >
                <div style={{flex: 7, alignItems: 'center', justifyContent: 'flex-end', display: "flex", height: "77%"}}>
                    <MascotteComponent
                        size={1}
                        file={this.props.hasAlready ? this.props.mascotteNext["file"] : this.props.mascotteFirst["file"]}
                        orientation={this.props.hasAlready ? this.props.mascotteNext["orientation"] : this.props.mascotteFirst["orientation"]}
                        type={this.props.hasAlready ? this.props.mascotteNext["type"] : this.props.mascotteFirst["type"]}
                        dialogue={this.props.hasAlready ? this.props.dialogueNext : this.props.dialogueFirst}
                    />
                </div>
                {!this.props.hasAlready && this.generateStarRain(this.props.nbXp)}
                <div style={{flex:2}}/>
            </div>
        )
    }
}

export default StarRainComponent;