import styles from './Empty.module.css';
import React, {Component} from "react"

class Empty extends Component {

    render () {

        return (
            <div className={styles.div_100}>
            </div>
        );
    }
}

export default Empty;
