import React, {Component} from "react"
import style from "./Screen.module.css"
import ScreenTemplate from "./ScreenTemplate";

class Screen extends Component {

    render() {
        return (
            <div className={style.container}>
                <ScreenTemplate/>
            </div>
        )
    }
}

export default Screen;