import React, {Component} from "react"
import style from "./Whois.module.css"
import Header from "../../Header/Header";
import ScreenWhois from "./ScreenWhois";
import Footer from "../../Footer/Footer";


class Whois extends Component {


    render() {
        return (
            <div className={style.mainContainer}>

                <div className={style.headerContainer}>
                    <Header
                        name={this.props.activity.name}
                        theme={this.props.activity.theme}
                        themeColor={this.props.activity.themeColor}
                        themeBaseColor={this.props.activity.themeBaseColor}
                        secondTheme={this.props.activity.secondTheme}
                        secondThemeColor={this.props.activity.secondThemeColor}
                        secondThemeBaseColor={this.props.activity.secondThemeBaseColor}
                        duration={this.props.activity.estimatedDuration}
                        ageMin={this.props.activity.ageMin}
                        progressBar={false}
                    />
                </div>

                <div className={style.screenContainer}>
                    <ScreenWhois
                        users={this.props.users}
                        mascotte={this.props.mascotte}
                        choose={this.props.choose.bind(this)}
                    />
                </div>

                <div className={style.footerContainer}>
                    <Footer
                        showNext={false}
                        xp={this.props.activity.xp}
                        hideLeave={true}
                        hideStars={true}
                    />
                </div>

            </div>
        )
    }
}

export default Whois;