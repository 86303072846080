import React, {Component} from "react"
import style from "./ScreenWhois.module.css"
import MascotteQuizComponent from "../../Screen/ScreenComponents/MascotteQuizzComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faCloud, faHeart, faSquare} from "@fortawesome/free-solid-svg-icons";
import MascotteComponent from "../../Screen/ScreenComponents/MascotteComponent";

class ScreenWhois extends Component {

    constructor(props) {
        super(props)

        this.state = {
            height: 0,
            width: 0,
            heightMax: false,
        }
    }

    componentDidMount() {
        const height = this.divElement.clientHeight;
        const width = this.divElement.clientWidth;
        this.setState({
            height: height,
            width: width,
            heightMax: (height/width < 338/236),
            margin: (width/height > 338/236) ? (width - 338*0.95*height/236)/2 + 5 : 90
        });
    }

    getUsers() {
        let buttons = [];

        let nbLine = Math.floor(this.props.users.length / 2) + this.props.users.length % 2;

        for(let i = 0; i < nbLine; i++){
            let line = [];
            if(i % 2 === 0){
                line.push(
                    <button style={{width: '52.5%'}} className={style.leftAnswer} onClick={() => this.props.choose(2*i)}>
                        <img src={require("../../../assets/Quiz/Rect_rouge.png")} className={style.leftImg}/>
                        <div className={style.answerTextContainer} style={{width: "80%", left: 0}}>
                            <FontAwesomeIcon icon={faSquare} style={{left: 0}} className={style.icon}/>
                            <p className={style.answerText}>
                                {this.props.users[2 * i].user.firstName} {this.props.users[2 * i].user.lastName}
                            </p>
                        </div>

                    </button>
                )

                if(2 * (i + 1) <= this.props.users.length){
                    line.push(
                        <button style={{width: '52.5%'}} className={style.rightAnswer} onClick={() => this.props.choose(2*i+1)}>
                            <img src={require("../../../assets/Quiz/Rect_jaune.png")} className={style.rightImg}/>
                            <div className={style.answerTextContainer} style={{width: "80%", right: 0}}>
                                <FontAwesomeIcon icon={faCircle} style={{right: 0}} className={style.icon}/>
                                <p className={style.answerText}>
                                    {this.props.users[2 * i + 1].user.firstName} {this.props.users[2 * i + 1].user.lastName}
                                </p>
                            </div>
                        </button>
                    )
                }

                buttons.push(
                    <div className={style.answerLine} style={{height: 0.47*this.state.height, marginBottom: 0.02*this.state.height}}>
                        {line}
                    </div>
                )
            } else {

                line.push(
                    <button style={{width: '52.5%'}}  className={style.leftAnswer} onClick={() => this.props.choose(2*i)}>
                        <img src={require("../../../assets/Quiz/Rect_vert.png")} className={style.leftImg}/>
                        <div className={style.answerTextContainer} style={{width: "80%", left: 0}}>
                            <FontAwesomeIcon icon={faHeart} style={{left: 0}} className={style.icon}/>
                            <p className={style.answerText}>
                                {this.props.users[2 * i].user.firstName} {this.props.users[2 * i].user.lastName}
                            </p>
                        </div>
                    </button>
                )

                if(2 * (i + 1) <= this.props.users.length){
                    line.push(
                        <button style={{width: '53.8%'}}  className={style.rightAnswer} onClick={() => this.props.choose(2*i+1)}>
                            <img src={require("../../../assets/Quiz/Rect_violet.png")} className={style.rightImg}/>
                            <div className={style.answerTextContainer} style={{width: "80%", right: 0}}>
                                <FontAwesomeIcon icon={faCloud} style={{right: 0}} className={style.icon}/>
                                <p className={style.answerText}>
                                    {this.props.users[2 * i + 1].user.firstName} {this.props.users[2 * i + 1].user.lastName}
                                </p>
                            </div>
                        </button>
                    )
                }

                buttons.push(
                    <div className={style.answerLine} style={{height: 0.47*this.state.height, marginBottom: 0.02*this.state.height}}>
                        {line}
                    </div>
                )
            }
        }

        if(nbLine == 1){
            buttons.push(<div className={style.answerLine} style={{height: 0.47*this.state.height, marginBottom: 0.02*this.state.height}}/>)
        }

        return buttons
    }

    render() {
        return (
            <div className={style.container}>
                <div style={{flex: 1, display: "flex", flexDirection: "column"}}>

                    {/*  Mascotte  */}
                    <div style={{flex: 4, display: "flex", maxHeight: "40%"}}>
                        {this.state.margin &&
                            <MascotteComponent
                                orientation={"droite"}
                                dialogue={this.props.mascotte.dialogue}
                                file={this.props.mascotte.file}
                                margin={this.state.margin}
                                type={this.props.mascotte.type}
                                size={1}
                            />
                        }
                    </div>

                    <div
                        style={{flex: 6, display: "flex", alignItems: "center", justifyContent: "center", overflowY: "auto"}}
                        ref={ (divElement) => { this.divElement = divElement } }
                    >
                        <div style={{
                            aspectRatio : 338/236,
                            maxHeight: "100%",
                            maxWidth: "100%",
                            height: this.state.heightMax ? "95%" : undefined,
                            width: this.state.heightMax ? undefined : "95%",
                            gap: this.state.height*0.02
                        }}>

                            {this.getUsers()}

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default ScreenWhois;