import React, {Component} from "react"
import style from "./Header.module.css"
import ActivityInfos from "./ActivityInfos";
import ProgressBar from "./ProgressBar/ProgressBar";

class Header extends Component {

    constructor(props) {
        super(props);
        this.lastDone = this.props.lastDone
        this.stepNumber = this.props.stepNumber
        this.starSteps = {0: {'done': false}}
    }

    setLastDone(nb) {
        if(nb > this.lastDone) {
            this.lastDone = nb;
            this.forceUpdate()
        }
    }

    setStepNumber(nb) {
        this.stepNumber = nb
        this.forceUpdate()
    }

    setStepIsStar = (nb) => {
        this.starSteps[nb + 1] = {'done': false}
        this.forceUpdate()
    }

    setStarIsDone = (nb) => {
        if(Object.keys(this.starSteps).includes((nb + 1).toString())) {
            this.starSteps[nb + 1].done = true
            this.forceUpdate()
        }
    }

    render() {
        return (
            <div className={style.header}>

                <div className={style.activityInfosContainer}>
                    <ActivityInfos
                        name={this.props.name}
                        theme={this.props.theme}
                        themeColor={this.props.themeColor}
                        themeBaseColor={this.props.themeBaseColor}
                        secondTheme={this.props.secondTheme}
                        secondThemeColor={this.props.secondThemeColor}
                        secondThemeBaseColor={this.props.secondThemeBaseColor}
                        duration={this.props.duration}
                        ageMin={this.props.ageMin}
                    />
                </div>

                <div className={style.progressBarContainer}>
                    <ProgressBar
                        nbSteps={this.props.nbSteps}
                        lastDone={this.lastDone}
                        stepNumber={this.stepNumber}
                        progressBar={this.props.progressBar}
                        changeStepNumber={this.props.changeStepNumber}
                        starSteps={this.starSteps}
                        navigation={this.props.navigation}
                    />
                </div>

            </div>
        )
    }
}

export default Header;