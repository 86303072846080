import {useParams} from "react-router-dom";
import React from "react";
import ActivityHub from "./ActivityHub";

function GetUuidActivity () {
    let {uuid} = useParams()

    return (
        <ActivityHub uuid={uuid}/>
    )
}

export default GetUuidActivity;
