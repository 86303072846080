import React, {Component} from "react"

class Line extends Component {

    constructor(props) {
        super(props);
        this.starDivRef = 0;
        this.fullWidth = false;
    }

    animate() {
        this.starDivRef.animate([
            {width: "0%"},
            {width: "100%"}
        ], {
            duration: 400,
            easing: 'linear'
        })
        setTimeout(() => {this.fullWidth = true; this.forceUpdate()}, 400)
    }

    render() {
        if(!this.props.done && !this.props.current && this.fullWidth){
            this.fullWidth = false;
            this.forceUpdate()
        }

        if(this.props.current && !this.fullWidth && this.starDivRef !== 0){
            this.animate()
        }

        return (
            <div style={{height: "90px", aspectRatio: 1/2, display: "flex", alignItems: "center", margin: "5px -10px"}}>
                <div
                    style={{
                        height: "10px",
                        flex: 1,
                        backgroundColor: "#EEEEEE",
                        position: "relative"
                }}>
                    <div
                        ref={(node) => {this.starDivRef = node}}
                        style={{
                            backgroundColor: this.props.done ? "#6fa939" : "#333",
                            height: "100%",
                            width: this.props.done || (this.props.current && this.fullWidth) ? "100%" :"0%",
                            position: "absolute",
                            left: this.props.orientation === "left" ? 0 : undefined,
                            right: this.props.orientation === "right" ? 0 : undefined
                    }}/>
                </div>
            </div>
        )
    }
}



export default Line;