import React, {Component} from "react"

class EmptyComponent extends Component {

    render() {
        return (
            <div style={{flex: this.props.size}}/>
        )
    }
}

export default EmptyComponent;