import React, {Component} from "react"

class TurnLeftBottom extends Component {

    constructor(props) {
        super(props);
        this.turnRef = 0;
        this.fullWidth = false;
    }


    animate() {
        this.turnRef.animate([
            {width: "0%", height: "10px"},
            {width: "100%", height: "100%"}
        ], {
            duration: 200,
            easing: 'linear'
        })
        setTimeout(() => {this.fullWidth = true; this.forceUpdate()}, 200)
    }

    render() {
        if(!this.props.done && !this.props.current && this.fullWidth){
            this.fullWidth = false;
            this.forceUpdate()
        }

        if(this.props.current && !this.fullWidth && this.turnRef !== 0){
            this.animate()
        }

        return (
            <div style={{height: "95px", width: "50px", display: "flex", alignItems: "flex-end", margin: "5px 0 0 -20px"}}>
                <div style={{overflow: "hidden", position: "relative", height: "50px", flex: 1, backgroundColor: "#EEEEEE", borderTopRightRadius: "50px", display: "flex", alignItems: "flex-end"}}>
                    <div
                        ref={(node) => {this.turnRef = node}}
                        style={{
                            height: this.fullWidth ? "100%" : "10px",
                            width: this.fullWidth ? "100%" : "0%",
                            position: "absolute",
                            top: 0, left: 0,
                            backgroundColor: this.props.current &&!this.props.done ? "#333" : this.props.done ? "#6fa939": "#EEEEEE"
                        }}/>
                    <div style={{zIndex: 10, height: "40px", backgroundColor: "#F9FBFD", borderTopRightRadius: "40px", width: "calc(100% - 10px)"}}>
                    </div>
                </div>
            </div>
        )
    }
}

export default TurnLeftBottom;