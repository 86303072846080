import React, {Component} from "react"
import style from "./Finish.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faCircleCheck, faFrown, faHome, faMeh, faSmile} from "@fortawesome/free-solid-svg-icons";
import {faFrown as faFrownRegular, faMeh as faMehRegular, faSmile as faSmileRegular} from "@fortawesome/free-regular-svg-icons";
import MascotteComponent from "./ScreenComponents/MascotteComponent";
import {api_url} from "../../Services/ConnectionService";
import Lottie from "lottie-react";
import star from "../../assets/Etoile.svg";

class Finish extends Component {

    constructor(props) {
        super(props)
        this.currentBadge = {}
        this.currentBadgeNb = 0;
        this.isFirstBadge = true;
        this.isFirstXp = true;
        this._lottie = undefined;
        this.state = {
            selected: undefined,
            isOpen: false
        };
        this.starsRender = [];
        this.starsVar = [];
        this.divRef = undefined;
        this.starRef = undefined;
        this.divTop = 0;
        this.divLeft = 0;
        this.divWidth = 0;
        this.divHeight = 0;
        this.starTop = 0;
        this.starLeft = 0;
        this.shouldGoXp = false;
    }

    select(mark) {
        this.props.setNote(mark - 1)
        this.setState({selected: mark})
        this.props.goPhoto();
    }

    nextBadgeOrClose() {
        if (this.currentBadgeNb < this.props.newBadgesNb - 1) {
            this.currentBadgeNb = this.currentBadgeNb + 1
            this.currentBadge = this.props.newBadges[this.currentBadgeNb]
            this.forceUpdate()
        } else {
            this.setState({isOpen: false})
            this.shouldGoXp = true;
        }
    }

    getLottie() {
        if(this._lottie === undefined){
            let request = new Request(api_url + '/uploads/badge/' + this.currentBadge.media, {method:'POST'});

            fetch(request).then((response) =>response.json())
                .then(data => {
                    this._lottie = data;
                    this.forceUpdate();
                })
        }
    }

    getBadge() {
        if(this.currentBadge.type === "lottie"){
            if(this._lottie){
                return (
                    <Lottie
                        animationData={this._lottie}
                        loop={true}
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}/>
                )
            } else {
                this.getLottie()
            }
        } else {
            return (
                <img
                    src={api_url + '/uploads/badge/' + this.currentBadge.media}
                    alt={"Badge"}
                    style={{height: "100%", width:"100%", objectFit: "contain"}}
                />
            )
        }
    }

    generateStarXp() {
        if(this.isFirstXp && this.props.xp > 0 && !this.state.isOpen) {
            let res = [];
            for(let i=0; i<5; i++) {
                this.starsVar.push({
                    x: 0,
                    y: 0,
                    size: 0,
                    ref: null
                })
                res.push(
                    <div
                        ref={(node) =>{this.starsVar[i].ref = node}}
                        key={i.toString()}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: this.starsVar[i].size,
                            height: this.starsVar[i].size,
                            zIndex: 100000,
                            transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px)"
                        }}>
                        <img src={star} style={{width: '100%', height: '100%'}} alt={'a star :)'}/>
                    </div>
                )
            }
            setTimeout(this.animateXpGain.bind(this), 500)
            this.starsRender = res;
            this.isFirstXp = false;
            return res
        }
        return this.starsRender
    }

    styleStringForStar(star) {
        const base = "align-items: center; justify-content: center; position: absolute; z-index: 100000; top: 0px; left: 0px; "
        const size = "width: " + this.starsVar[star].size.toString() + "px; height: " + this.starsVar[star].size.toString() + "px; "
        const transform = "transform: translate(" + this.starsVar[star].x.toString() + "px, " + this.starsVar[star].y.toString() + "px);"
        return base + size + transform
    }

    getRandom(min, max) {
        return Math.floor(min + (max-min)*Math.random())
    }

    animateXpGain() {
        this.divTop = this.divRef.getBoundingClientRect().top
        this.divLeft = this.divRef.getBoundingClientRect().left
        this.divWidth = this.divRef.clientWidth
        this.divHeight = this.divRef.clientHeight
        this.starTop = this.starRef.getBoundingClientRect().top
        this.starLeft = this.starRef.getBoundingClientRect().left
        for(let i in this.starsVar) {
            let newX = this.starLeft - this.divLeft + this.getRandom(-60, 60)
            let newY = this.starTop - this.divTop + this.getRandom(-60, 60)
            this.starsVar[i].x = newX;
            this.starsVar[i].y = newY;
            setTimeout(() => {
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 5)
            setTimeout(() => {
                this.starsVar[i].ref.animate([
                    {width: "0px", height: "0px"},
                    {width: "50px", height: "50px"},
                ], {
                    duration: 200,
                    easing: 'linear'
                })
            }, 50)
            setTimeout(() => {
                this.starsVar[i].size = 50
            }, 150)
            setTimeout(() => {
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 155)
            setTimeout(() => {
                this.starsVar[i].ref.animate([
                    {transform: "translate(" + this.starsVar[i].x.toString() + "px, " + this.starsVar[i].y.toString() + "px)"},
                    {transform: "translate(" + (this.starsVar[i].x + (3*(this.divWidth - this.starsVar[i].x))/4).toString() + "px, " + (this.starsVar[i].y + (3*(0 - this.starsVar[i].y))/4).toString() + "px)"},
                ], {
                    duration: 605,
                    easing: 'ease-in'
                })
            }, 600)
            setTimeout( () => {
                this.starsVar[i].ref.animate([{
                    transform: "translate(" + (this.starsVar[i].x + (3*(this.divWidth - this.starsVar[i].x))/4).toString() + "px, " + (this.starsVar[i].y + (3*(0 - this.starsVar[i].y))/4).toString() + "px)",
                    width: this.starsVar[i].size.toString() + "px",
                    height: this.starsVar[i].size.toString() + "px",
                }, {
                    transform: "translate(" + this.divWidth.toString() + "px, 0px)",
                    width: 0,
                    height: 0
                },
                ], {
                    duration: 150,
                    easing: 'ease-in'
                })}, 1200)
            setTimeout(() => {
                this.starsVar[i].x = this.divWidth
                this.starsVar[i].y = 0
                this.starsVar[i].size = 0
                this.starsVar[i].ref.style = this.styleStringForStar(i)
            }, 1300)
        }
        setTimeout(() => {this.props.addXp(this.props.xp); this.props.expandStar()}, 1300)
    }

    displayBadgesModal() {
        return (
            <div
                style={{
                    zIndex: 90000,
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%", width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        width:  window.innerWidth > 1020 ? "50%" : "calc(100% - 60px)",
                        padding: window.innerWidth > 1020 ? "40px" : "20px",
                    }}
                    className={style.modal}
                >
                    <span className={style.title} style={{color: '#3EA79D'}}>Nouveau badge !</span>

                    <div
                        style={{
                            borderRadius: "100px",
                            flex: 3,
                            aspectRatio: 1,
                            margin: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                            maxHeight: "20%"
                        }}>
                        {this.getBadge()}
                    </div>

                    <span className={style.subtitle}>{this.currentBadge.name}</span>

                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        onClick={this.nextBadgeOrClose.bind(this)}
                        className={style.iconBtn}
                        style={{color: this._rightAnswer === this.state.selected ? '#3EA79D' : '#CE3534'}}
                    />
                </div>
            </div>
        )
    }

    render() {
        if(this.props.newBadgesNb > 0 && this.props.isFocus && this.isFirstBadge) {
            this.isFirstBadge = false;
            this.currentBadge = this.props.newBadges[this.currentBadgeNb]
            this.setState({isOpen : true})
        } else if(this.props.isFocus) {
            this.shouldGoXp = true;
        }
        return (
            <div className={style.container} ref={(node) => {this.divRef = node}}>
                <div className={style.mascotteContainer}>

                    <MascotteComponent
                        file={this.props.mascotte.file}
                        dialogue={this.props.dialogue}
                        orientation={this.props.mascotte.orientation}
                        size={6}
                    />

                    { this.props.xp > 0 &&
                        <div
                            style={{
                                flex: 4,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "1.8rem",
                                textAlign: "center",
                                flexDirection: "column"
                        }}>
                                <div>
                                    Tu as gagné  <br/>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "5px"}}>
                                        {this.props.xp}
                                        <img
                                            src={require("../../assets/Etoile.svg").default}
                                            style={{height: window.innerWidth > 1700 ? "50px" : window.innerWidth > 1400  ? "40px" : "30px", aspectRatio: 1}}
                                            ref={(node) => {this.starRef = node}}
                                        />
                                    </div>
                                </div>
                        </div>
                    }
                </div>

                <div className={style.markContainer}>
                    <div style={{fontSize: "1.5vmax"}}>As-tu aimé l'activité ?</div>
                    <div className={style.smileyContainer}>
                        <button
                            className={style.smileyBtn}
                            onClick={() => this.select(1)}
                        >
                            <FontAwesomeIcon icon={this.state.selected === 1 ? faFrown : faFrownRegular} color={"#CE3534"}/>
                        </button>

                        <button
                            className={style.smileyBtn}
                            onClick={() => this.select(2)}
                        >
                            <FontAwesomeIcon icon={this.state.selected === 2 ? faMeh : faMehRegular} color={"#FFBA08"}/>
                        </button>

                        <button
                            className={style.smileyBtn}
                            onClick={() => this.select(3)}
                        >
                            <FontAwesomeIcon icon={this.state.selected === 3 ? faSmile : faSmileRegular} color={"#3EA79D"}/>
                        </button>
                    </div>
                </div>

                <div className={style.btnContainer}>

                </div>

                {this.state.isOpen && this.displayBadgesModal()}
                {this.shouldGoXp && this.generateStarXp()}
            </div>
        )
    }
}

export default Finish;