import './App.css';
import {Link, Route, Routes} from "react-router-dom";
import Home from "./components/Home"
import Empty from "./components/Empty";
import GetUuidActivity from "./components/Activity/GetUuidActivity";
import GetUuidBoxActivity from "./components/Activity/GetUuidBoxActivity";
import GetIdsStartActivity from "./components/Activity/GetIdsStartActivity";
import GetIdsActivity from "./components/Activity/GetIdsActivity";
import {client_url} from "./Services/ConnectionService";
import NotFound from "./components/Error/NotFound";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Client/>}/>
        <Route path="/activity/find/:uuid" element={<GetUuidActivity/>}/>
        <Route path="/activity/box/:uuid" element={<GetUuidBoxActivity/>}/>
        <Route path="/activity/start/:activity/:user" element={<GetIdsStartActivity/>}/>
        <Route path="/activity/:activity/:user" element={<GetIdsActivity/>}/>
        <Route path="/error" element={<NotFound/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
  );
}

function Client() {
    // 👇️ redirect to external URL
    window.location.replace(client_url);

    return null;
}

export default App;
