import React, {Component} from "react"

class EmptyLine extends Component {

    render() {
        return (
            <div style={{height: "90px", aspectRatio: 1/2, display: "flex", alignItems: "center", margin: "5px -10px"}}/>
        )
    }
}

export default EmptyLine;